<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-form ref="form" class="flex-grow-1" @submit.prevent="handleUpdateUser">
      <validation-provider
        v-if="newUserEmail"
        v-slot="{ errors }"
        name="email"
        :rules="{
          required: true
        }"
        ><v-text-field
          label="Email"
          prepend-inner-icon="mdi-email"
          v-model="newUserEmail"
          :error-messages="errors"
          type="email"
          required
          :disabled="true"
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Password"
        :rules="{
          required: true,
          confirmed: 'confirmPassword',
          regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
        }"
        ><v-text-field
          label="Set a Password"
          prepend-inner-icon="mdi-lock"
          v-model="credentials.password"
          :error-messages="errors"
          type="password"
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="Confirm Password"
        vid="confirmPassword"
      >
        <v-text-field
          v-model="confirmPassword"
          :error-messages="errors"
          type="password"
          label="Confirm Password"
          prepend-inner-icon="mdi-lock"
          hint="Passwords must be at least 8 characters long and include a number, letter, and special character (@ $ ! % * # ? &)."
          persistent-hint
        ></v-text-field>
      </validation-provider>

      <div class="mt-8 text-center">
        <v-btn
          @click="handleUpdateUser"
          :disabled="invalid"
          x-large
          color="mt-6 primary darken-2"
        >
          Set Password
        </v-btn>
      </div>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { tempUserAvatar } from "@/helpers/tempUserAvatar";

export default {
  name: "NewUserPassword",
  props: {
    newUserEmail: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      credentials: {
        password: "",
        data: {
          avatar: tempUserAvatar(this.newUserEmail)
        }
      },
      confirmPassword: ""
    };
  },
  computed: {
    ...mapGetters("app", ["isDevEnvironment", "siteURL"])
  },
  methods: {
    ...mapActions("auth", ["updateUserAccount"]),
    handleUpdateUser() {
      this.loading = true;

      this.updateUserAccount({ ...this.credentials })
        .then(() => {
          console.log("Account has been updated.");
          this.$emit("accountUpdated", this.credentials);
        })
        .catch(error => {
          console.error("Failed to update user: ", error);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.pgi-error-msg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
}
</style>
