<template>
  <BaseCenteredCardLanding
    cardColor="rgb(18 25 45 / 85%)"
    :cardStyles="{
      boxShadow:
        'rgb(80 203 255) 0px 1px 1px 0px inset, rgb(80 203 255 / 13%) 0px 50px 100px -20px, rgb(80 203 255 / 15%) 0px 30px 60px -30px'
    }"
  >
    <template #cardSubheader
      ><p class="mt-6 text-h6 text-center primary--text">
        {{ headerText }}
      </p></template
    >

    <template #primaryColumn>
      <h3 class="mb-5">{{ formMessage }}</h3>
      <div v-if="isLoading" class="py-16 d-flex align-center justify-center">
        <v-progress-circular
          v-if="isLoading"
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <template v-if="!isLoading && !showAlert">
        <!-- LOGIN FORM -->
        <LoginForm
          v-if="!showSetPassword"
          :userEmail="userEmail"
          @onLoginSuccess="onLoginSuccess"
        />
        <!-- New User Set Password Form -->
        <UpdateUserForm
          v-if="showSetPassword"
          :newUserEmail="userEmail"
          @accountUpdated="onAccountUpdated"
        />
      </template>

      <template v-if="showAlert && !isLoading">
        <v-alert dense outlined type="success" class="mt-5">
          Your password has been updated! You can now login to PlanGap Insights.
        </v-alert>
        <v-btn @click="onSwitchToLogin" x-large color="mt-6 primary darken-2"
          >Go to Login</v-btn
        >
      </template>
    </template>

    <template #secondaryColumn>
      <div>
        <h3 class="mb-5" v-if="!showSetPassword">Trouble logging in?</h3>
        <h3 class="mb-5" v-if="showSetPassword">Having Trouble?</h3>
        <p>
          Call:
          <a href="tel:8337526427" class="text-decoration-none"
            >1-833-PLANGAP</a
          >
        </p>
        <p>
          Email:
          <a
            href="mailto:insights@plangap.com?subject=Insights Login Issue"
            class="text-decoration-none"
            >insights@plangap.com</a
          >
        </p>
        <!-- <p>
          <router-link to="/faq" class="text-decoration-none primary--text"
            >Check out the FAQ</router-link
          >
        </p> -->
      </div>

      <v-spacer></v-spacer>

      <p class="text-caption">
        Don't have an account?
        <router-link
          to="/access"
          class="text-decoration-none text-no-wrap primary--text"
          >Request Access
          <v-icon small color="primary">mdi-arrow-right</v-icon></router-link
        >
      </p>
    </template>

    <template #backgroundGraphic>
      <!-- <img src="@/assets/images/line-chart.svg" /> -->
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 698.26 256.03">
        <path
          d="M-12.7,262.58c17.45,0,34.91-87.29,64-87.29,40.73,0,40.73,34.92,69.82,34.92,52.37,0,52.37-110.56,110.56-110.56,52.37,0,52.37,52.37,98.92,52.37,64,0,64-98.92,122.19-98.92s58.19,58.19,128,58.19c64,0,40.73-58.2,104.74-58.19V262.58Z"
          transform="translate(12.7 -6.55)"
          fill="#caf1ff"
          opacity="0.5"
        />
        <path
          d="M-12.7,262.58c75.64,0,93.1-139.66,139.65-139.66S173.5,152,220.05,152c58.19,0,58.19-122.2,139.65-122.2,58.19,0,58.19,69.83,93.1,69.83C511,99.65,511,6.55,569.18,6.55s58.19,58.19,116.38,58.19V262.58Z"
          transform="translate(12.7 -6.55)"
          fill="#a7e4fe"
          opacity="0.5"
        />
        <path
          d="M-12.7,262.58c64,0,75.64-69.83,116.37-69.83s40.74,29.09,69.83,29.09c52.37,0,52.37-98.92,133.83-98.92,58.19,0,58.19,46.55,116.38,46.55,69.83,0,69.83-98.92,145.47-98.92,69.83,0,69.83,52.37,116.38,52.37V262.58Z"
          transform="translate(12.7 -6.55)"
          fill="#50cbff"
          opacity="0.5"
        />
      </svg>
    </template>
  </BaseCenteredCardLanding>
</template>

<script>
import BaseCenteredCardLanding from "@/components/BaseCenteredCardLanding";
import LoginForm from "@/components/LoginForm";
import UpdateUserForm from "@/components/UpdateUserForm";

export default {
  components: {
    BaseCenteredCardLanding,
    LoginForm,
    UpdateUserForm
  },
  data() {
    return {
      showSetPassword: false,
      userEmail: "",
      isLoading: false,
      formMessage: "Login",
      showAlert: false,
      headerText: "Welcome. Please login."
    };
  },
  beforeMount() {
    if (this.$route.query.inviteAccepted === "true") {
      this.showSetPassword = true;
      this.headerText = "Invite Confirmation";
      this.formMessage = "Account Password";
    }
    if (this.$route.query.email) {
      this.userEmail = this.$route.query.email;
    }
  },
  mounted() {},
  methods: {
    onAccountUpdated() {
      this.headerText = "Invite Confirmation";
      this.formMessage = "Updating password...";
      this.isLoading = true;
      setTimeout(() => {
        this.formMessage = "Password Updated!";
        this.showSetPassword = false;
        this.isLoading = false;
        this.showAlert = true;
      }, 1500);
    },
    onLoginSuccess() {
      this.headerText = "Welcome. Please login.";
      this.formMessage = "Attempting login...";
      this.isLoading = true;
      setTimeout(() => {
        this.$router.push("/dashboard");
      }, 1500);
    },
    onSwitchToLogin() {
      this.headerText = "Welcome. Please login.";
      this.formMessage = "Login";
      this.showSetPassword = false;
      this.showAlert = false;
    }
  }
};
</script>
