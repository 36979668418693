<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <v-form ref="form" class="flex-grow-1" @submit.prevent="handleLogin">
      <validation-provider
        v-slot="{ errors }"
        name="email"
        :rules="{
          required: true
        }"
        ><v-text-field
          label="Email"
          prepend-inner-icon="mdi-email"
          v-model="credentials.email"
          :error-messages="errors"
          type="email"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider
        v-slot="{ errors }"
        name="password"
        :rules="{
          required: true
        }"
        ><v-text-field
          label="Password"
          prepend-inner-icon="mdi-lock"
          v-model="credentials.password"
          :error-messages="errors"
          type="password"
          required
        ></v-text-field>
      </validation-provider>

      <div v-if="isDevEnvironment">
        <div>
          👋 Dev Environment. Set Netlify app url (without https://).
          <div>
            <small v-if="siteURL" class="warning--text"
              >URL IS SET: https://{{ siteURL }}
            </small>
            <br />
            <v-text-field
              v-model="netlifyURL"
              type="text"
              placeholder="YOUR-NETLIFY-SITE.netlify.app"
            ></v-text-field>

            <v-btn @click="setURL()" small color="primary darken-2">
              SET URL
            </v-btn>
          </div>
        </div>
      </div>

      <div class="mt-8 text-center">
        <v-alert v-if="showMessage" dense outlined :type="alertType">
          {{ message }}
        </v-alert>

        <v-btn
          @click="handleLogin"
          :disabled="invalid"
          x-large
          color="mt-6 primary darken-2"
        >
          Login
        </v-btn>
      </div>
    </v-form>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "LoginForm",
  props: {
    userEmail: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      netlifyURL: "",
      message: "",
      showMessage: false,
      alertType: "error",
      authenticated: false,
      credentials: {
        email: this.userEmail ? this.userEmail : "",
        password: ""
      }
    };
  },
  computed: {
    ...mapGetters("app", ["isDevEnvironment", "siteURL"])
  },
  methods: {
    ...mapActions("auth", ["attemptLogin"]),
    setURL() {
      this.$store.commit("app/SET_SITE_URL", this.netlifyURL);
    },
    async handleLogin() {
      try {
        this.showMessage = false;

        const loggedIn = await this.attemptLogin({ ...this.credentials });

        if (loggedIn) {
          this.$emit("onLoginSuccess");
        }
      } catch (error) {
        console.log("Login error:", error.json);
        this.showMessage = true;
        if (error.json.error_description) {
          this.message = error.json.error_description;
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pgi-error-msg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
}
</style>
