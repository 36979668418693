var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",staticClass:"flex-grow-1",on:{"submit":function($event){$event.preventDefault();return _vm.handleUpdateUser($event)}}},[(_vm.newUserEmail)?_c('validation-provider',{attrs:{"name":"email","rules":{
        required: true
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","prepend-inner-icon":"mdi-email","error-messages":errors,"type":"email","required":"","disabled":true},model:{value:(_vm.newUserEmail),callback:function ($$v) {_vm.newUserEmail=$$v},expression:"newUserEmail"}})]}}],null,true)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Password","rules":{
        required: true,
        confirmed: 'confirmPassword',
        regex: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Set a Password","prepend-inner-icon":"mdi-lock","error-messages":errors,"type":"password"},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Confirm Password","vid":"confirmPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":"password","label":"Confirm Password","prepend-inner-icon":"mdi-lock","hint":"Passwords must be at least 8 characters long and include a number, letter, and special character (@ $ ! % * # ? &).","persistent-hint":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})]}}],null,true)}),_c('div',{staticClass:"mt-8 text-center"},[_c('v-btn',{attrs:{"disabled":invalid,"x-large":"","color":"mt-6 primary darken-2"},on:{"click":_vm.handleUpdateUser}},[_vm._v(" Set Password ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }