<template>
  <v-container fluid px-0 py-6 pb-6 fill-height>
    <v-row justify="center">
      <v-col cols="12" md="8" lg="6" mx-auto>
        <v-card
          :color="cardColor"
          class="mx-auto pa-8 pgi-asset-card"
          :style="{ ...cardStyles }"
        >
          <div class="pb-8">
            <router-link to="/">
              <v-img
                contain
                max-width="350"
                src="@/assets/images/pg-insights-logo-r.svg"
                class="mx-auto mb-8"
              ></v-img
            ></router-link>

            <slot name="cardSubheader" />
          </div>
          <v-row>
            <v-col cols="12" md="7" order-md="3">
              <slot name="primaryColumn" />
            </v-col>
            <v-col cols="12" md="1" order-md="2">
              <v-divider horizontal class="d-md-none"></v-divider>
              <v-divider vertical class="d-none d-md-flex"></v-divider>
            </v-col>

            <v-col cols="12" md="4" order-md="1" class="d-flex  flex-column">
              <slot name="secondaryColumn" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <div class="pgi-access-graphic">
      <slot name="backgroundGraphic" />
    </div>
    <v-img
      v-if="bgGraphicFileName"
      :src="require('@/assets/images/' + bgGraphicFileName)"
      class="pgi-access-graphic pgi-access-graphic--50"
    ></v-img>
  </v-container>
</template>

<script>
export default {
  props: {
    cardColor: {
      type: String,
      required: false
    },
    cardStyles: {
      type: Object,
      required: false
    },
    bgGraphicFileName: {
      type: String,
      required: false
    }
  }
};
</script>

<style lang="scss" scoped>
.pgi-asset-card {
  position: relative;
  z-index: 1;
}

.pgi-access-graphic {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  opacity: 0.08;

  &--50 {
    height: 50%;
  }

  > svg {
    vertical-align: bottom;
  }
}
</style>
