var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{ref:"form",staticClass:"flex-grow-1",on:{"submit":function($event){$event.preventDefault();return _vm.handleLogin($event)}}},[_c('validation-provider',{attrs:{"name":"email","rules":{
        required: true
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","prepend-inner-icon":"mdi-email","error-messages":errors,"type":"email","required":""},model:{value:(_vm.credentials.email),callback:function ($$v) {_vm.$set(_vm.credentials, "email", $$v)},expression:"credentials.email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"password","rules":{
        required: true
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","prepend-inner-icon":"mdi-lock","error-messages":errors,"type":"password","required":""},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}})]}}],null,true)}),(_vm.isDevEnvironment)?_c('div',[_c('div',[_vm._v(" 👋 Dev Environment. Set Netlify app url (without https://). "),_c('div',[(_vm.siteURL)?_c('small',{staticClass:"warning--text"},[_vm._v("URL IS SET: https://"+_vm._s(_vm.siteURL)+" ")]):_vm._e(),_c('br'),_c('v-text-field',{attrs:{"type":"text","placeholder":"YOUR-NETLIFY-SITE.netlify.app"},model:{value:(_vm.netlifyURL),callback:function ($$v) {_vm.netlifyURL=$$v},expression:"netlifyURL"}}),_c('v-btn',{attrs:{"small":"","color":"primary darken-2"},on:{"click":function($event){return _vm.setURL()}}},[_vm._v(" SET URL ")])],1)])]):_vm._e(),_c('div',{staticClass:"mt-8 text-center"},[(_vm.showMessage)?_c('v-alert',{attrs:{"dense":"","outlined":"","type":_vm.alertType}},[_vm._v(" "+_vm._s(_vm.message)+" ")]):_vm._e(),_c('v-btn',{attrs:{"disabled":invalid,"x-large":"","color":"mt-6 primary darken-2"},on:{"click":_vm.handleLogin}},[_vm._v(" Login ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }